import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import './App.css';
import TrackerView from "./Components/Tracker/TrackerView"

function App() {
  return (
    <div>
      <Router>
        {/* <Header></Header>  */}
        <Switch>
          <Route path="/" exact component={TrackerView} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
