import React, {Component} from "react"
import { Map } from 'react-leaflet'

class MapContainer extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
    }
    
    render() {
        return (
					<Map style={{
						width: Math.floor(this.props.width),
						height:  Math.floor(this.props.height),
					}} {...this.props} >
						{this.props.children}
					</Map>
        )
    }
}

export default MapContainer