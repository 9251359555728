import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import { TileLayer, Polyline } from 'react-leaflet'
import * as L from "leaflet";
import GeoUtil from "leaflet-geometryutil";
import ContainerDimensions from 'react-container-dimensions'
import MapContainer from './MapContainer';
import TruckMarker from "./TruckMarker";
import JsxMarker from "./JsxMarker";

const apiIsLoaded = (map, setMap) => {
	setMap(map);
};

const fps = 60;
const mspr = 12000;
const timeBetweenMapdata = 6000;

const center = [51.1726744, 4.1326231];
const radiusInKm = 200;

const markerALatLng = L.marker(center).getLatLng();

class TrackerView extends Component {
	constructor() {
		super();

		const url = new URL(window.location.href);
		const apikey = url.searchParams.get("apikey");

		this.state = {
			map: null,

			angle: 0,
			polyline: [],
			locations: [],

			apikey: apikey ?? null,
		};

		this.truckLocations = [];

		this.setMap = this.setMap.bind(this);
		this.moveMap = this.moveMap.bind(this);		
		this.getMapdata = this.getMapdata.bind(this);		
		this.frameRender = this.frameRender.bind(this);		
	}
	
	componentDidMount() {
		const timeBetweenFrames = 1000/ fps;
		this.frameRenderInterval = setInterval(this.frameRender, timeBetweenFrames);
		this.getMapdataInterval = setInterval(this.getMapdata, timeBetweenMapdata);
		this.getMapdata();
	}

	componentWillUnmount() {
		if (this.frameRenderInterval) clearTimeout(this.frameRenderInterval);
		if (this.getMapdataInterval) clearTimeout(this.getMapdataInterval);
	}

	static defaultProps = {
		center: {
			lat: process.env.REACT_APP_DEFAULT_LAT,
			lng: process.env.REACT_APP_DEFAULT_LNG,
		},
		zoom: 10,
	};

	moveMap(lat, long) {
		if (lat != this.state.lastFocusLatitude || long != this.state.lastFocusLongitude) {
			this.setState({
				lastFocusLatitude: lat,
				lastFocusLongitude: long,
			});
			if (this.state.map != null && !(lat == 0 && long == 0)) {
				this.state.map.panTo({
					lat: parseFloat(lat),
					lng: parseFloat(long)
				});
			}
		}
	}

	setMap(map) {
		if (!this.state.map) {
			this.setState({
				map: map
			});
		}
	}

	getMapdata() {
		const that = this;
		const url = process.env.REACT_APP_API_HREF+"icecreamvanmarkerdata";
		fetch(url, {
			headers: {
				"Apikey": this.state.apikey,
			}
		})
		.then(response => response.json())
		.then(data => {
			that.truckLocations = data.data.filter(truckLocation => truckLocation.latitude > 0 && truckLocation.longitude > 0).map(truckLocation => {
				console.log(truckLocation);
				const markerC = L.marker([truckLocation.latitude, truckLocation.longitude]);
				console.log(markerC);
				let newMarkerRelativeAngle = GeoUtil.bearing(markerALatLng, markerC.getLatLng());
				if (newMarkerRelativeAngle < 0) {
					newMarkerRelativeAngle = 180+(180-Math.abs(newMarkerRelativeAngle));
				}
	
				return {
					id: truckLocation.id,
					lat: truckLocation.latitude,
					lng: truckLocation.longitude,
					angle: newMarkerRelativeAngle,
				};
			});
		})
		.catch((e) => {
			console.error(e);
		});
}

	frameRender() {
		const oldAngle = this.state.angle;
		const newAngle = this.state.angle + ( ((1000/ fps) / mspr)*360 );
		const startOfCircleAngle = Math.floor(newAngle/360)*360;
		const relativeOldAngel = oldAngle%360;
		const relativeNewAngel = newAngle%360;

		const pointB = L.point(GeoUtil.destination(markerALatLng, oldAngle, radiusInKm * 1000));

		const polyline = [
			[markerALatLng.lat, markerALatLng.lng],
			[pointB.x.lat, pointB.x.lng],
		];

		let locations = this.state.locations.filter(location => location.angle >= newAngle-360 );

		const truckLocations = this.truckLocations.filter(truckLocation => truckLocation.angle >= relativeOldAngel && truckLocation.angle <= relativeNewAngel);
		truckLocations.forEach(truckLocation => {
			const markerC = L.marker([truckLocation.lat, truckLocation.lng]);
			let newMarkerRelativeAngle = GeoUtil.bearing(markerALatLng, markerC.getLatLng());
			if (newMarkerRelativeAngle < 0) {
				newMarkerRelativeAngle = 180+(180-Math.abs(newMarkerRelativeAngle));
			}

			locations.push({
				key: truckLocation.id+"-"+newAngle,
				lat: markerC.getLatLng().lat,
				lng: markerC.getLatLng().lng,
				angle: startOfCircleAngle+newMarkerRelativeAngle,
			});
		});

		this.setState({
			angle: newAngle,
			polyline: polyline,
			locations: locations,
		});
	}
	
	render() {
			return (
				<main>
					<div className={"content contentNoHeader"}>
						{/* Important! Always set the container height explicitly */}
						<div className={"mapdivNoHeader"}>
							<ContainerDimensions>
								<MapContainer center={this.props.center} zoom={this.props.zoom} ref='map'>
									<TileLayer
										attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
										url={process.env.REACT_APP_TILE_SERVER_HREF+"?z={z}&x={x}&y={y}"}
										onLoad={(props) => apiIsLoaded(props.target._map, this.setMap)}
									/>
									<Polyline color={"#03A062"} positions={this.state.polyline} />
									{this.state.locations.map(location => <TruckMarker key={location.key} lat={location.lat} lng={location.lng} />)}
								</MapContainer>
							</ContainerDimensions>
						</div>
					</div>
				</main>
			)
	}
}

export default TrackerView;