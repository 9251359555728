import React, {Component} from "react"
import { Link } from "react-router-dom";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import JsxMarker from "./JsxMarker";

class TruckMarker extends Component {
    constructor() {
        super();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.key != nextProps.key;
    }
    
    render() {
        return (
            <JsxMarker position={{lat: this.props.lat, lng: this.props.lng}}>
                <div className="blob red" />
            </JsxMarker>
        )
    }
}

export default TruckMarker